import { Component, Input } from '@angular/core';
import { Room } from "../../models/room";
import { RouterLink, RouterLinkActive } from "@angular/router";

@Component({
	selector: 'app-navbar',
	standalone: true,
	imports: [
		RouterLink,
		RouterLinkActive
	],
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.css'
})
export class NavbarComponent {
	@Input() rooms?: Room[];
}
