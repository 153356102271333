import { Component, OnDestroy } from '@angular/core';
import { PaginatorModule } from "primeng/paginator";
import { ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { Product } from "../../models/product";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ProductInfo } from "../../models/product-info";
import { RoundButtonComponent } from "../round-button/round-button.component";
import { ProductService } from "../../services/product.service";
import { Subscription } from "rxjs";

@Component({
	selector: 'app-product-info',
	standalone: true,
	imports: [
		PaginatorModule,
		ReactiveFormsModule,
		InputTextModule,
		InputTextareaModule,
		RoundButtonComponent
	],
	templateUrl: './product-info.component.html',
	styleUrl: './product-info.component.css'
})
export class ProductInfoComponent implements OnDestroy {
	public product?: Product;
	private productSubscription?: Subscription;
	private productInfoSubscription?: Subscription;

	constructor(
		private dialogRef: DynamicDialogRef,
		private productService: ProductService,
		config: DynamicDialogConfig
	) {
		const data = config.data.product as Product;

		if (data) {
			this.product = data;

			if (!this.product.info) {
				this.product.info = [];
			}

			if (this.product.info.length === 0) {
				this.product.info.push({ url: '', price: 0 });
			}
		}
	}

	ngOnDestroy(): void {
		this.productSubscription?.unsubscribe();
		this.productInfoSubscription?.unsubscribe();
	}

	public addPrice = (): void => {
		if (this.product && !this.product.info?.find(info => info.url === '')) {
			this.product.info?.push({ url: '', price: 0 });
		}
	}

	public saveInfo = (product: Product): void => {
		const productInfo: ProductInfo[] = product.info?.filter(info => info.url !== undefined && info.url !== '') ?? [];

		if (product.id && productInfo.length > 0) {
			this.productSubscription = this.productService.addProductInfo(product.id, productInfo)
				.subscribe({
					next: (product: Product) => this.product = product,
					complete: () => this.dialogRef.close(this.product)
				});
		}
	}

	public removeProductInfo = (productInfo: ProductInfo): void => {
		if (this.product && productInfo.url !== undefined && productInfo.url !== '') {
			this.productInfoSubscription = this.productService.deleteProductInfo(this.product.id, productInfo)
				.subscribe({
					complete: () => {
						if (this.product) {
							this.product.info = this.product?.info?.filter(info => info !== productInfo);
							this.product.price = this.product?.info?.length === 0 ? 0 : this.product.price;

							if (this.product.info?.length === 0) {
								this.product.info.push({ url: '', price: 0 });
							}
						}
					}
				});
		}
	}
}
