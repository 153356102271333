import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { Price } from "../models/price";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class PriceService {
	private _price: Subject<void>;

	constructor(private httpClient: HttpClient) {
		this._price = new Subject<void>();
	}

	public getPrice = (roomId: string | undefined): Observable<Price> => {
		let params: HttpParams = new HttpParams();

		if (roomId) {
			params = params.set('roomId', roomId);
		}

		return this.httpClient.get<Price>(`${ environment.apiUrl }/price`, { params: params });
	}

	public notify = () => {
		this._price.next();
	}

	public getPriceUpdates = (): Observable<void> => {
		return this._price.asObservable();
	}
}
