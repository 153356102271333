import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NavbarComponent } from "./components/navbar/navbar.component";
import { RoomService } from "./services/room.service";
import { Subscription } from "rxjs";
import { Room } from "./models/room";
import { CurrencyPipe, NgClass } from "@angular/common";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { PriceService } from "./services/price.service";
import { Price } from "./models/price";
import { InputSwitchChangeEvent, InputSwitchModule } from "primeng/inputswitch";
import { FormsModule } from "@angular/forms";

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, NavbarComponent, NgClass, CurrencyPipe, InputSwitchModule, FormsModule],
	templateUrl: './app.component.html',
	styleUrl: './app.component.css'
})
export class AppComponent implements OnInit, OnChanges, OnDestroy {
	public rooms: Room[];
	public isMobile?: boolean;
	public displayedPrice: number;
	public filterCurrentRoom: boolean;

	private roomSubscription?: Subscription;

	constructor(
		private roomService: RoomService,
		private router: Router,
		private priceService: PriceService,
		private changeDetector: ChangeDetectorRef,
		breakpointObserver: BreakpointObserver
	) {
		breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
			.subscribe({
				next: value => this.isMobile = value.matches
			});
		this.displayedPrice = 0;
		this.filterCurrentRoom = false;
		this.rooms = [];
	}

	ngOnInit(): void {
		this.roomSubscription = this.roomService.getRooms()
			.subscribe({
				next: (room: Room) => {
					this.rooms.push(room);
					this.changeDetector.detectChanges();
				},
				complete: () => this.router.navigate(['room', this.rooms[0].id]),
				error: err => console.error(err)
			});

		this.getPrice();

		this.priceService.getPriceUpdates()
			.subscribe({
				next: () => this.getPrice()
			});
	}

	ngOnChanges(): void {
		this.getPrice();
	}

	ngOnDestroy() {
		this.roomSubscription?.unsubscribe();
	}

	public updatePrice = (event: InputSwitchChangeEvent): void => {
		this.filterCurrentRoom = event.checked;
		this.getPrice();
	}

	private getPrice = (): void => {
		const roomId = this.filterCurrentRoom ? this.router.url.substring(this.router.url.lastIndexOf('/') + 1) : undefined;

		this.priceService.getPrice(roomId)
			.subscribe({
				next: (data: Price) => this.animatePriceChange(this.displayedPrice, data.price)
			});
	}

	private animatePriceChange(start: number, end: number): void {
		const duration = 250;
		const startTime = performance.now();

		const animate = (currentTime: number) => {
			const elapsed = currentTime - startTime;
			const progress = Math.min(elapsed / duration, 1);
			this.displayedPrice = start + (end - start) * progress;

			if (progress < 1) {
				requestAnimationFrame(animate);
			}

			this.changeDetector.detectChanges();
		};

		requestAnimationFrame(animate);
	}
}
