import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Room } from "../models/room";
import { EventSourceUtils } from "../utils/event-source-utils";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class RoomService {

	public getRooms = (): Observable<Room> => {
		return EventSourceUtils.toObservable<Room>(`${ environment.apiUrl }/rooms`);
	}

}
