<div class="container">
	<div class="wrapper">
		<form class="form" [formGroup]="productForm">
			<input type="text" pInputText placeholder="Nome" formControlName="name"/>
			<textarea class="description" pInputTextarea placeholder="Descrizione" formControlName="description"></textarea>
			<div class="row">
				<input class="image-url" type="text" pInputText placeholder="URL Immagine" formControlName="imageUrl"/>
				<button class="capture-image" (click)="getImage(image)">
					<i class="pi pi-camera capture-image-icon"></i>
				</button>
			</div>
		</form>
		<input type="file" accept="image/*" capture="environment" (change)="imageUploaded($event)" #image>

		<p-button styleClass="save-product" (click)="saveProduct(image)" [loading]="isLoading">
			{{ operationType === 'ADD' ? 'Aggiungi' : 'Aggiorna' }}
		</p-button>
	</div>
</div>