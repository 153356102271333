import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from "primeng/dynamicdialog";
import { DialogData } from "../../models/dialog-data";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ProductService } from "../../services/product.service";
import { Product } from "../../models/product";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Button } from "primeng/button";

@Component({
	selector: 'app-edit-product',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		InputTextareaModule,
		InputTextModule,
		DynamicDialogModule,
		Button
	],
	templateUrl: './edit-product.component.html',
	styleUrl: './edit-product.component.css'
})
export class EditProductComponent implements OnDestroy {
	public productForm: FormGroup;
	public operationType: 'ADD' | 'EDIT';
	public isLoading: boolean;

	private roomId?: string;
	private product?: Product;
	private productSubscription?: Subscription;

	constructor(
		private productService: ProductService,
		private dialogRef: DynamicDialogRef,
		config: DynamicDialogConfig,
		route: ActivatedRoute
	) {
		const data = config.data.data as DialogData;

		if (data.type === 'EDIT') {
			const product = data.product;

			if (product) {
				this.productForm = new FormGroup({
					id: new FormControl<string | undefined>(product.id),
					name: new FormControl<string>(product.name, [Validators.required]),
					description: new FormControl<string | undefined>(product.description),
					imageUrl: new FormControl<string | undefined>(product.image)
				});
			} else {
				this.productForm = this.getEmptyForm();
			}
		} else {
			this.productForm = this.getEmptyForm();
		}

		route.params
			.subscribe({
				next: params => this.roomId = params["id"]
			});

		this.operationType = data.type;
		this.isLoading = false;
	}

	ngOnDestroy() {
		this.productSubscription?.unsubscribe();
	}

	public getImage = (image: HTMLInputElement): void => {
		image.click();
	}

	public saveProduct = (image: HTMLInputElement): void => {
		this.isLoading = true;
		const file: File | undefined = image.files?.[0];
		const product: Product = {
			name: this.productForm.get('name')?.value ?? '',
			price: this.productForm.get('price')?.value ?? 0,
			roomId: this.roomId ?? '',
			description: this.productForm.get('description')?.value,
			image: this.productForm.get('imageUrl')?.value,
			type: this.productForm.get('type')?.value,
		}

		if (this.operationType === 'EDIT') {
			const id = this.productForm.get('id')?.value;

			if (id) {
				this.productSubscription = this.productService.updateProduct(id, product, file)
					.subscribe({
						next: (product: Product) => this.product = product,
						complete: () => this.dialogRef.close(this.product)
					});
			}
		} else {
			this.productSubscription = this.productService.createProduct(product, file)
				.subscribe({
					next: (product: Product) => this.product = product,
					complete: () => this.dialogRef.close(this.product)
				});
		}
	}

	public imageUploaded = (event: Event): void => {
		const fileName: string | undefined = (event.target as HTMLInputElement).files?.[0].name;
		this.productForm.patchValue({
			imageUrl: fileName
		});
	}

	private getEmptyForm = (): FormGroup => {
		return new FormGroup({
			name: new FormControl<string>('', [Validators.required]),
			description: new FormControl<string | undefined>(undefined),
			imageUrl: new FormControl<string | undefined>(undefined)
		});
	}
}
