<div class="container">
	<div class="products-wrapper">
		@for (product of products; track product.id) {
			<div class="product">
				<app-product [product]="product" (toggleChange)="updatePrice()"
							 (removeProductEmitter)="removeProduct($event)"/>
			</div>
		}
		<div class="add-button">
			<app-round-button (click)="addProduct()">+</app-round-button>
		</div>
	</div>
</div>