import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Product } from "../../models/product";
import { filter, Subscription } from "rxjs";
import { ProductService } from "../../services/product.service";
import { ProductComponent } from "../product/product.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { RoundButtonComponent } from "../round-button/round-button.component";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { EditProductComponent } from "../edit-product/edit-product.component";
import { DialogData } from "../../models/dialog-data";
import { CurrencyPipe } from "@angular/common";
import { PriceService } from "../../services/price.service";
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: 'app-category',
	standalone: true,
	imports: [
		ProductComponent,
		RoundButtonComponent,
		CurrencyPipe
	],
	providers: [DialogService],
	templateUrl: './category.component.html',
	styleUrl: './category.component.css'
})
export class CategoryComponent implements OnDestroy {
	public products: Product[];
	public isMobile?: boolean;
	public displayedPrice: number;

	private productsSubscription?: Subscription;
	private dialogRef?: DynamicDialogRef;

	constructor(
		private productService: ProductService,
		private dialogService: DialogService,
		private priceService: PriceService,
		private changeDetector: ChangeDetectorRef,
		breakpointObserver: BreakpointObserver,
		route: ActivatedRoute
	) {
		breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
			.subscribe({
				next: value => this.isMobile = value.matches
			});
		this.displayedPrice = 0;
		this.products = [];

		route.params
			.subscribe(params => {
				this.products = [];
				this.loadProducts(params["id"]);
			});
	}

	ngOnDestroy() {
		this.productsSubscription?.unsubscribe();
	}

	public addProduct = (): void => {
		const dialogData: DialogData = {
			type: "ADD"
		};
		this.dialogRef = this.dialogService.open(EditProductComponent, {
			header: `Aggiungi nuovo`,
			modal: true,
			data: { data: dialogData },
			width: '85vw',
			baseZIndex: 10000,
			dismissableMask: true
		});

		this.dialogRef.onClose
			.pipe(
				filter(product => product !== undefined)
			)
			.subscribe({
				next: (product: Product) => {
					this.products.push(product);
					this.changeDetector.detectChanges();
				}
			});
	}

	public updatePrice = (): void => {
		this.priceService.notify();
	};

	public removeProduct = (product: Product): void => {
		this.products = this.products.filter(p => p.id !== product.id);
		this.changeDetector.detectChanges();
	}

	private loadProducts = (id: string): void => {
		this.productsSubscription = this.productService.getProductsByRoomId(id)
			.subscribe({
				next: (product: Product): void => {
					this.products.push(product);
					this.changeDetector.detectChanges();
				}
			});
	}
}
