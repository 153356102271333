import { Observable } from "rxjs";

export class EventSourceUtils {

	public static toObservable = <T> (url: string): Observable<T> => {
		return new Observable<T>(observe => {
			const eventSource = new EventSource(url);

			eventSource.onmessage = event => observe.next(JSON.parse(event.data));
			eventSource.onerror = event => {
				if (eventSource.readyState === EventSource.CONNECTING || eventSource.readyState === EventSource.CLOSED) {
					eventSource.close();
					observe.complete();
				} else {
					observe.error(event);
				}
			};

			return () => eventSource.close();
		});
	}

}