<div class="container">
	@if (product) {
		<div class="product-wrapper" [ngClass]="{'mobile': isMobile}">
			<div class="row">
				@if (isMobile) {
					<h2 class="product-name mobile">{{ product.name }}</h2>
				} @else {
					<h1 class="product-name">{{ product.name }}</h1>
				}

				<p-inputSwitch [(ngModel)]="product.enabled" (onChange)="toggleProduct($event, product)"/>
			</div>

			@if (product.description) {
				<p class="product-description" [innerHTML]="getProductDescription(product)"></p>
			}

			@if (product.image) {
				<div class="image-wrapper">
					<img class="product-image" [src]="product.image" alt="">
				</div>
			}

			<div class="price">
				<span class="label">Prezzo:</span> {{ product.price | currency:'EUR' }}
			</div>

			<div class="buttons row">
				<button class="edit button" (click)="editProduct(product)">
					<i class="pi pi-pencil"></i>
				</button>
				<button class="info button" (click)="getProductInfo(product)">
					<i class="pi pi-info"></i>
				</button>
				<button class="remove button" (click)="removeProduct(product)" [disabled]="isDeleteLoading">
					<i class="pi pi-trash"></i>
				</button>
			</div>
		</div>
	}
</div>