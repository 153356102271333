import { Injectable } from '@angular/core';
import { mergeMap, Observable } from "rxjs";
import { Product } from "../models/product";
import { HttpClient } from "@angular/common/http";
import { EventSourceUtils } from "../utils/event-source-utils";
import { ProductInfo } from "../models/product-info";
import { environment } from "../../environments/environment";
import { EnableProduct } from "../models/enable-product";

@Injectable({
	providedIn: 'root'
})
export class ProductService {

	constructor(private httpClient: HttpClient) {
	}

	public getProductsByRoomId = (roomId: string): Observable<Product> => {
		return EventSourceUtils.toObservable<Product>(`${ environment.apiUrl }/products?roomId=${ roomId }`);
	}

	public createProduct = (product: Product, image: File | undefined): Observable<Product> => {
		if (image) {
			return this.uploadImage(image, product.roomId)
				.pipe(
					mergeMap(imageData => {
						product.image = imageData.imageUrl;
						return this.httpClient.post<Product>(`${ environment.apiUrl }/products`, product);
					})
				);
		}

		return this.httpClient.post<Product>(`${ environment.apiUrl }/products`, product);
	}

	public updateProduct = (id: string, product: Product, image: File | undefined): Observable<Product> => {
		if (image) {
		return this.uploadImage(image, product.roomId)
			.pipe(
				mergeMap(imageData => {
					product.image = imageData.imageUrl;
					return this.httpClient.put<Product>(`${ environment.apiUrl }/products/${ id }`, product);
				})
			)
		}

		return this.httpClient.put<Product>(`${ environment.apiUrl }/products/${ id }`, product);
	}

	public removeProduct = (product: Product): Observable<void> => {
		return this.httpClient.delete<void>(`${ environment.apiUrl }/products/${ product.id }`);
	}

	public addProductInfo = (productId: string, info: ProductInfo[]): Observable<Product> => {
		return this.httpClient.patch<Product>(`${ environment.apiUrl }/products/${ productId }/info`, info);
	}

	public deleteProductInfo = (id: string | undefined, productInfo: ProductInfo): Observable<void> => {
		return this.httpClient.delete<void>(`${ environment.apiUrl }/products/${ id }/info/${ productInfo.url }`);
	}

	public enableProduct = (enableProduct: EnableProduct): Observable<Product> => {
		return this.httpClient.patch<Product>(`${ environment.apiUrl }/products/${ enableProduct.id }/enable`, enableProduct);
	}

	private uploadImage = (image: File, roomId: string): Observable<{ imageUrl: string }> => {
		const formData: FormData = new FormData();
		formData.append('file', image, image.name);
		formData.append('roomId', roomId);

		return this.httpClient.post<{ imageUrl: string }>(`${ environment.apiUrl }/products/images`, formData);
	}
}
