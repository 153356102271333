import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Product } from "../../models/product";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { CurrencyPipe, NgClass } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ProductService } from "../../services/product.service";
import { DialogService, DynamicDialogModule, DynamicDialogRef } from "primeng/dynamicdialog";
import { EditProductComponent } from "../edit-product/edit-product.component";
import { DialogData } from "../../models/dialog-data";
import { ToggleProduct } from "../../models/toggle-product";
import { ProductInfoComponent } from "../product-info/product-info.component";
import { InputSwitchChangeEvent, InputSwitchModule } from "primeng/inputswitch";
import { filter } from "rxjs";

@Component({
	selector: 'app-product',
	standalone: true,
	imports: [
		NgClass,
		CurrencyPipe,
		FormsModule,
		InputSwitchModule,
		DynamicDialogModule
	],
	providers: [DialogService],
	templateUrl: './product.component.html',
	styleUrl: './product.component.css'
})
export class ProductComponent implements OnInit {
	@Input() product?: Product;
	@Output() toggleChange: EventEmitter<ToggleProduct>;
	@Output() removeProductEmitter: EventEmitter<Product>;
	@Output() addProductEmitter: EventEmitter<Product>;

	public isMobile?: boolean;
	public isDeleteLoading: boolean;
	private dialogRef?: DynamicDialogRef
	private productInfoRef?: DynamicDialogRef

	constructor(
		private breakpointObserver: BreakpointObserver,
		private productService: ProductService,
		private dialogService: DialogService,
		private changeDetector: ChangeDetectorRef
	) {
		this.toggleChange = new EventEmitter<ToggleProduct>();
		this.removeProductEmitter = new EventEmitter<Product>();
		this.addProductEmitter = new EventEmitter<Product>();
		this.isDeleteLoading = false;
	}

	ngOnInit() {
		this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
			.subscribe({
				next: value => this.isMobile = value.matches
			});
	}

	public editProduct = (product: Product): void => {
		const dialogData: DialogData = {
			product: product,
			type: "EDIT"
		};
		this.dialogRef = this.dialogService.open(EditProductComponent, {
			header: `Modifica ${ product.name }`,
			modal: true,
			data: { data: dialogData },
			width: '85vw',
			baseZIndex: 10000,
			dismissableMask: true
		});

		this.dialogRef.onClose
			.pipe(
				filter(product => product !== undefined)
			)
			.subscribe({
				next: (product: Product) => {
					this.product = product;
					this.changeDetector.detectChanges();
				}
			});
	}

	public removeProduct = (product: Product): void => {
		this.isDeleteLoading = true;
		this.changeDetector.detectChanges();

		this.productService.removeProduct(product)
			.subscribe({
				next: () => this.removeProductEmitter.emit(product),
				complete: () => {
					this.isDeleteLoading = false;
					this.changeDetector.detectChanges();
				}
			});
	}

	public toggleProduct = (event: InputSwitchChangeEvent, product: Product): void => {
		const status = event.checked;

		if (product.id) {
			this.productService.enableProduct({ id: product.id, enabled: product.enabled ?? false })
				.subscribe({
					next: (product: Product) => {
						this.product = product;
						this.toggleChange.emit({ price: product.price, isEnabled: status });
					}
				});
		}
	}

	public getProductInfo = (product: Product): void => {
		this.productInfoRef = this.dialogService.open(ProductInfoComponent, {
			header: `Info ${ product.name }`,
			modal: true,
			data: { product: product },
			width: '85vw',
			baseZIndex: 10000,
			dismissableMask: true
		});

		this.productInfoRef.onClose
			.pipe(
				filter(product => product !== undefined)
			)
			.subscribe({
				next: (product: Product) => this.product = product
			});
	}

	public getProductDescription = (product: Product): string => {
		if (product.description) {
			const descriptionParts = product.description.split('\n');

			if (descriptionParts.length > 1) {
				let returnValue = '<ul style="padding-left: 10px">';

				descriptionParts.forEach(descriptionPart => {
					returnValue += `<li>${ descriptionPart }</li>`;
				});

				return returnValue.concat("</ul>");
			}

			return product.description;
		}

		return "";
	}
}
