<div class="container">
	@if (!isMobile) {
		<div class="header-wrapper">
			<div class="navbar">
				<app-navbar [rooms]="rooms"/>
			</div>
			<div class="total-price">
				<div class="row price-wrapper">
					<h2 class="label">Totale</h2>
					<span class="price">{{ displayedPrice | currency:'EUR' }}</span>
				</div>
			</div>
		</div>
	} @else {
		<div class="total-wrapper">
			<div class="total">
				<div class="row mobile price">
					<h2 class="label">Totale</h2>
					<span class="price">{{ displayedPrice | currency:'EUR' }}</span>
				</div>
				<div class="row mobile filter">
					<p-inputSwitch id="filter" [(ngModel)]="filterCurrentRoom" (onChange)="updatePrice($event)" />
					<label for="filter">Filtra per stanza attuale</label>
				</div>
			</div>
		</div>
		<div class="navbar-wrapper" [ngClass]="{'mobile': isMobile}">
			<app-navbar [rooms]="rooms"/>
		</div>
	}

	<router-outlet/>
</div>