@if (product && product.info) {
	<div class="container">
		<div class="wrapper">
			<div class="content">
				@for (info of product.info; track info.url; let index = $index) {
					<div class="row">
						<button class="pi pi-times remove" (click)="removeProductInfo(info)"></button>
						<input type="text" class="url" [(ngModel)]="product.info[index].url" pInputText
							   placeholder="Sito">
						<div class="price-wrapper">
							<p-inputNumber [(ngModel)]="product.info[index].price"
										   styleClass="price"
										   inputId="currency-italy"
										   mode="currency"
										   currency="EUR"
										   locale="it-IT"
										   placeholder="Prezzo"/>
						</div>
					</div>
				}
			</div>

			<div class="add-price">
				<app-round-button (click)="addPrice()">
					+
				</app-round-button>
			</div>

			<button class="save-product" (click)="saveInfo(product)">
				Salva
			</button>
		</div>
	</div>
}